import { Button, Dropdown, Icon, Menu } from 'antd'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DateRangeSelectorGroup from './DateRangeSelectorGroup'

// ****************
// React Components
// ****************

// Dropdown overlay that appears when hovering over the
// Platform selector component. Chooses DAM or MST.
const PlatformDropdown = ({ menuItems, onSelect }) => (
  <Menu>
    {menuItems.map((platform) => (
      <Menu.Item
        key={platform.key}
        onClick={() => {
          onSelect(platform.key)
        }}
      >
        {platform.label}
      </Menu.Item>
    ))}
  </Menu>
)

// Header component that displays the Analytics page title,
// the Date Selector component, and the Platform Selector.
class AnalyticsHeader extends Component {
  render() {
    const {
      title,
      platformMenuItems,
      dateRangeMenuItems,
      activeAnalyticsPlatform,
      activeAnalyticsDuration,
      analyticsCustomDateRange,
      setActiveAnalyticsPlatform,
      setActiveAnalyticsDuration,
      setAnalyticsCustomDateRange,
    } = this.props

    console.log({ platformMenuItems, activeAnalyticsPlatform })
    const activeAnalyticsPlatformLabel = platformMenuItems.find(
      (item) => item.key === activeAnalyticsPlatform
    ).label

    return (
      <div style={styles.container}>
        {/* Left: Title */}
        <div style={styles.leftContainer}>
          <span style={styles.titleText}>{title}</span>
        </div>

        {/* Center: Date Range Select */}
        <div style={styles.centerContainer}>
          <DateRangeSelectorGroup
            menuItems={dateRangeMenuItems}
            activeDuration={activeAnalyticsDuration}
            setActiveDuration={setActiveAnalyticsDuration}
            activeDateRange={analyticsCustomDateRange}
            setActiveDateRange={setAnalyticsCustomDateRange}
          />
        </div>

        {/* Right: Platform Selector Button */}
        <div style={styles.rightContainer}>
          <Dropdown
            overlay={
              <PlatformDropdown
                menuItems={platformMenuItems}
                onSelect={setActiveAnalyticsPlatform}
              />
            }
            placement="bottomLeft"
          >
            <Button type="primary" style={styles.platformSelectorBtn}>
              <span style={styles.platformSelectorText}>
                {activeAnalyticsPlatformLabel}
              </span>
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
      </div>
    )
  }
}

// ******
// Styles
// ******
const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  leftContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '20px',
    minWidth: '300px',
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 20px',
  },
  titleText: {
    fontFamily: 'Roboto',
    fontSize: '28px',
    color: '#545454',
    fontWeight: 'bold',
  },
  platformSelectorText: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  platformSelectorBtn: {
    width: 108,
  },
}

// *********
// PropTypes
// *********
PlatformDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
}

AnalyticsHeader.propTypes = {
  title: PropTypes.string,
  platformMenuItems: PropTypes.arrayOf(PropTypes.object),
  dateRangeMenuItems: PropTypes.arrayOf(PropTypes.object),
  activeAnalyticsPlatform: PropTypes.string,
  activeAnalyticsDuration: PropTypes.string,
  analyticsCustomDateRange: PropTypes.arrayOf(PropTypes.string),
  setActiveAnalyticsPlatform: PropTypes.func,
  setActiveAnalyticsDuration: PropTypes.func,
  setAnalyticsCustomDateRange: PropTypes.func,
}

export default AnalyticsHeader
