import { Layout } from 'antd'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import FourOhFour from './common/FourOhFour'
import PrivacyPolicy from './common/PrivacyPolicy'
import ForgotPassword from './public/ForgotPassword'
import Login from './public/Login.js'
import Register from './public/Register'
import RegistrationPending from './public/RegistrationPending'
import ResetPassword from './public/ResetPassword'
import ResetPasswordSuccess from './public/ResetPasswordSuccess'
import './styles/App-Public.css'

const { Sider, Content } = Layout

const PublicApp = () => (
  <Layout className="public-layout">
    <Sider className="public-sider" width="20%">
      <img
        className="public-sider__logo"
        src="/BAM-logo-v2.svg"
        alt="BAM! logo"
      />
    </Sider>
    <Content className="public-content__container">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password/:code" component={ResetPassword} />
        <Route
          path="/reset-password-success"
          component={ResetPasswordSuccess}
        />
        <Route path="/register/:invitation?" component={Register} />
        <Route path="/registration-pending" component={RegistrationPending} />
        <Route path="/fourohfour" component={FourOhFour} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </Content>
  </Layout>
)

export default PublicApp
