import { Button, Dropdown, Icon, Menu, Modal } from 'antd'
import imgGen from 'js-image-generator'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as superuserActions from '../../dux/actions/superuser.js'
import { getEndpointOfNode } from '../../dux/modules/utils'
import Maintenance from './utils/Maintenance'
import Reconcile from './utils/Reconcile'
import Reports from './utils/Reports'
import Signout from './utils/Signout'
import StressTest from './utils/StressTest'
import UploadUsers from './utils/UploadUsers.js'

class Superuser extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: 0,
      stressCount: 1,
      filelist: [],
      random: true,
      key: 'Stress Test',
      reconcileActionCreated: false,
      startDate: '',
      endDate: '',
      reportMessage: '',
      signoutEmail: '',
      signoutMessage: '',
    }
    this.createAsset = () => {
      let title = this.getFakeWords(Math.floor(Math.random() * 3) + 1)
      let description = this.getFakeWords(Math.floor(Math.random() * 25) + 1)
      let file
      this.state.random
        ? imgGen.generateImage(1600, 900, 80, function (err, image) {
            file = image.data
          })
        : (file =
            this.state.filelist[
              Math.floor(Math.random() * this.state.filelist.length)
            ])
      let asset = {
        owner: props.user.id,
        client: props.user.client,
        tags: [],
        title,
        description,
        filename: file.name || '',
      }
      return {
        asset,
        file,
      }
    }

    this.submitStressTest = () => {
      const ep = getEndpointOfNode(
        this.props.folders.byId[this.props.folders.activeFolder],
        this.props.folders.byId,
        this.props.endpoints.endpoints
      )
      if (!this.props.folders.activeFolder || ep.id !== 'LIB') {
        Modal.info({
          title: 'Error',
          content: (
            <div>
              <p>You must select a library folder</p>
            </div>
          ),
          onOk() {},
        })
        return
      }
      let result
      for (let i = 0; i < this.state.stressCount; i++) {
        result = this.createAsset()
        this.props.superuserActions.stressTest(
          i,
          result.asset,
          this.props.folders.activeFolder,
          result.file,
          this.props.user.token
        )
      }
      this.setState({ step: 3 })
    }

    this.createReconcileAction = () => {
      this.setState(() => ({ reconcileActionCreated: false }))
      this.props.superuserActions.reconcileRelationships()
      setTimeout(() => {
        this.setState(() => ({ reconcileActionCreated: true }))
      }, 500)
    }

    this.getFakeWords = (count) => {
      let result = ''
      for (let i = 0; i < count; i++) {
        result = `${result} test`
      }
      return result
    }

    this.handleFileChange = (e) => {
      this.setState({ filelist: e.target.files, step: 1 })
    }

    this.openFilePicker = () => {
      this.setState({ random: false })
      document.getElementById('fileInput').click()
    }

    this.menuClick = (e) => {
      this.setState({ key: e.key })
    }

    this.menu = (
      <Menu onClick={this.menuClick}>
        <Menu.Item key="Stress Test">Stress Test</Menu.Item>
        <Menu.Item key="Upload Users">Upload Users</Menu.Item>
        <Menu.Item key="Synch Search">Synch Search</Menu.Item>
        <Menu.Item key="Reconcile Relationships">
          Reconcile Relationships
        </Menu.Item>
        <Menu.Item key="Maintenance">Set Maintenance Mode</Menu.Item>
        <Menu.Item key="Reports">Reports</Menu.Item>
        <Menu.Item key="Signout">Admin User Sign Out</Menu.Item>
      </Menu>
    )

    this.turnOnMaintenance = () => {
      this.props.client.turnOnMaintenance()
    }

    this.createSnapshot = () => this.props.superuserActions.createSnapshot()

    this.turnOffMaintenance = () => {
      this.props.client.turnOffMaintenance()
    }
  }

  async componentDidMount() {
    let test = await fetch(`${this.props.config.baseUrl}/version.json`)
    let json = await test.json()
    this.setState({ version: json.BAMversion })
  }

  reportChangeHandler = (e) =>
    this.setState({ [e.target.id]: e.target.value, reportMessage: null })
  signoutChangeHandler = (e) =>
    this.setState({ [e.target.id]: e.target.value, signoutMessage: null })

  submitUserAuditReport = () => {
    this.props.superuserActions.submitUserAuditReport()
  }

  submitNewAssetReport = () => {
    this.props.superuserActions.submitNewAssetReport(
      this.state.startDate,
      this.state.endDate
    )
    this.setState({
      startDate: null,
      endDate: null,
      reportMessage: 'Report Submitted',
    })
  }

  onSignOut = () => {
    this.props.superuserActions.signoutUser(this.state.signoutEmail)
  }
  renderUtil = (key) => {
    switch (key) {
      case 'Stress Test':
        return (
          <StressTest
            step={this.state.step}
            openFilePicker={this.openFilePicker}
            handleFileChange={this.handleFileChange}
            handleRandom={() => this.setState({ random: true, step: 1 })}
            handleIterationChange={(e) => {
              this.setState({ stressCount: e.target.value })
            }}
            handleIterations={() => {
              this.setState({ step: 2 })
            }}
            submitStressTest={this.submitStressTest}
          />
        )
      case 'Upload Users':
        return <UploadUsers />
      case 'Reconcile Relationships':
        return (
          <Reconcile
            onClick={this.createReconcileAction}
            asyncPending={this.props.appState.asyncPending}
            reconcileActionCreated={this.state.reconcileActionCreated}
          />
        )
      case 'Maintenance':
        return (
          <Maintenance
            onClickOn={this.turnOnMaintenance}
            onClickOff={this.turnOffMaintenance}
            onClickSnapshot={this.createSnapshot}
            maintenance={this.props.maintenance.state}
          />
        )
      case 'Reports':
        return (
          <Reports
            onNewAssetReport={this.submitNewAssetReport}
            onUserAuditReport={this.submitUserAuditReport}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            changeHandler={this.reportChangeHandler}
            reportMessage={this.state.reportMessage}
          />
        )
      case 'Signout':
        return (
          <Signout
            signoutEmail={this.state.signoutEmail}
            signoutMessage={this.state.signoutMessage}
            changeHandler={this.signoutChangeHandler}
            onSignOut={this.onSignOut}
          />
        )

      default:
        break
    }
  }
  render() {
    return (
      <div style={{ margin: '50px' }}>
        <div>
          <h1>Utilities </h1>
          <h2>
            {this.state.version && (
              <span>(BAM version {this.state.version})</span>
            )}
            <Dropdown overlay={this.menu} trigger={['click']}>
              <Button style={{ marginLeft: 30 }}>
                {this.state.key} <Icon type="down" />
              </Button>
            </Dropdown>
          </h2>
        </div>
        {this.renderUtil(this.state.key)}
      </div>
    )
  }
}
Superuser.propTypes = {
  level: PropTypes.string,
  onUpdateUser: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.user,
  appState: state.appState,
  folders: state.folders,
  endpoints: state.endpoints,
  maintenance: state.appState.maintenance,
  config: state.config.appConfig,
  token: state.user.token,
})

const mapDispatchToProps = (dispatch) => ({
  superuserActions: bindActionCreators(superuserActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Superuser)
