import React from 'react'

import { HOTSPOT__SIZE } from '../../../../../dux/constants/builders'
import Title from 'antd/lib/typography/Title'
import { Spin, Tooltip } from 'antd'
import { calculateHotspotPosition, calculateImageDimensions } from '../utils'
import { Link } from 'react-router-dom'

/*
1.  determine if the height is greater than the width
*/

const Hotspot = ({
  hotspot,
  index,
  mainViewerWidth,
  mainViewerHeight,
  imageHeight,
  imageWidth,
  selected,
  setSelectedHotspot,
}) => {
  const originalAspectRatio = imageWidth / imageHeight

  const { bottom, left } = calculateHotspotPosition(hotspot.location, {
    mainViewerHeight,
    mainViewerWidth,
    imageHeight,
    imageWidth,
    originalAspectRatio,
  })
  return (
    <Link to={`/library/asset/${hotspot.assetId}`}>
      <Tooltip title={hotspot.displayText}>
        <div
          style={{
            position: 'absolute',
            bottom: bottom,
            left: left,
            width: HOTSPOT__SIZE,
            height: HOTSPOT__SIZE,
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '50%',
            color: 'black',
            display: 'flex',
            fontWeight: 'bold',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onMouseOver={() => setSelectedHotspot(index)}
        >
          {' '}
          <span>{selected ? '+' : index + 1}</span>{' '}
        </div>
      </Tooltip>
    </Link>
  )
}

export const SlideImage = (props) => {
  const {
    currentSlideImageUrl,
    isImageReady,
    mainViewerWidth,
    mainViewerHeight,
    slideImageHeight,
    slideImageWidth,
    hotspots,
    currentSelectedHotspot,
    setSelectedHotspot,
  } = props

  if (!currentSlideImageUrl) return <CouldNotFindImage />
  if (!isImageReady) return <Spin size="large" />

  const { height, width } = calculateImageDimensions({
    height: slideImageHeight,
    width: slideImageWidth,
    containerWidth: mainViewerWidth,
    containerHeight: mainViewerHeight,
  })

  return (
    <div
      style={{
        backgroundImage: `url(${currentSlideImageUrl})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height,
        width,
      }}
    >
      {hotspots.map((hotspot, index) => (
        <Hotspot
          {...{
            hotspot,
            index,
            selected: currentSelectedHotspot == index,
            mainViewerWidth,
            mainViewerHeight,
            slideImageHeight,
            slideImageWidth,
            imageHeight: height,
            imageWidth: width,
            setSelectedHotspot,
          }}
          key={hotspot.id}
        />
      ))}
    </div>
  )
}

const CouldNotFindImage = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    }}
  >
    <Title level={3} style={{ color: '#fff', textAlign: 'center' }}>
      Image not found <br /> Please make sure the image is still in the asset
      library
    </Title>
  </div>
)
