import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as folderActions from './folder'
import * as invitationActions from './invitation'
import * as modalActions from './modal'
import * as userActions from './user'

export const GET_USERS = 'GET_USERS'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_DELETED_USERS_SUCCESS = 'GET_DELETED_USERS_SUCCESS'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const EDIT_USER = 'EDIT_USER'
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS'
export const GET_GROUPS = 'GET_GROUPS'
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS'
export const ADD_GROUP = 'ADD_GROUP'
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS'
export const UPDATE_GROUP = 'UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS'
export const GET_ROLES = 'GET_ROLES'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_PRIVILEGES = 'GET_PRIVILEGES'
export const GET_PRIVILEGES_SUCCESS = 'GET_PRIVILEGES_SUCCESS'
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS'

export const getUsers = (setPendingFlag = true) => ({
  type: GET_USERS,
  setPendingFlag,
})

export function* watchGetUsers() {
  yield takeEvery(GET_USERS, getUsersSaga)
}

export function* getUsersSaga(action) {
  if (action.setPendingFlag)
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    // Active Users
    const users = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/all`,
      init: {
        method: 'GET',
      },
    })
    yield put({ type: GET_USERS_SUCCESS, users })

    //Deleted Users
    const deletedUsers = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/deleted`,
      init: {
        method: 'GET',
      },
    })
    yield put({ type: GET_DELETED_USERS_SUCCESS, users: deletedUsers })

    if (action.setPendingFlag) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const deleteUser = (user) => ({
  type: DELETE_USER,
  user,
})

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserSaga)
}

export function* deleteUserSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    // Remover user from groups, global table and cognito
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/users/delete`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.user),
      },
    })
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/addaction`,
      init: {
        method: 'POST',
        body: JSON.stringify({ action, events: [] }),
      },
    })
    yield put({ type: DELETE_USER_SUCCESS, user: action.user })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log('error', error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'An error occurred while deleting user',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const editUser = (user, newName, admin, selectedGroups, allGroups) => ({
  type: EDIT_USER,
  user,
  newName,
  admin,
  selectedGroups,
  allGroups,
})

export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUserSaga)
}

export function* editUserSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    // Update user name if needed
    if (action.user.name !== action.newName) {
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/users/update`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            email: action.user.email,
            updates: {
              name: action.newName,
            },
          }),
        },
      })
      yield put({
        type: EDIT_USER_SUCCESS,
        user: action.user,
        newName: action.newName,
      })
    }
    // Add user to admin group if needed
    if (
      action.admin &&
      action.allGroups['SYS.admins'].members.indexOf(action.user.email) < 0
    ) {
      action.allGroups['SYS.admins'].members.push(action.user.email)
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/permissions/groups/update`,
        init: {
          method: 'POST',
          body: JSON.stringify(action.allGroups['SYS.admins']),
        },
      })
      yield put({
        type: UPDATE_GROUP_SUCCESS,
        group: action.allGroups['SYS.admins'],
      })
    }
    // Remove user from admin group if needed
    if (
      !action.admin &&
      action.allGroups['SYS.admins'].members.indexOf(action.user.email) > -1
    ) {
      action.allGroups['SYS.admins'].members.splice(
        action.allGroups['SYS.admins'].members.indexOf(action.user.email),
        1
      )
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/permissions/groups/update`,
        init: {
          method: 'POST',
          body: JSON.stringify(action.allGroups['SYS.admins']),
        },
      })
      yield put({
        type: UPDATE_GROUP_SUCCESS,
        group: action.allGroups['SYS.admins'],
      })
    }
    // Modify other group membership as needed
    for (let grp of Object.keys(action.allGroups).filter(
      (g) => g !== 'SYS.admins'
    )) {
      if (action.selectedGroups.indexOf(grp) > -1) {
        if (action.allGroups[grp].members.indexOf(action.user.email) < 0) {
          action.allGroups[grp].members.push(action.user.email)
          yield call(apiActions.secureFetchSaga, {
            url: `${config.baseUrl}/api/permissions/groups/update`,
            init: {
              method: 'POST',
              body: JSON.stringify(action.allGroups[grp]),
            },
          })
          yield put({
            type: UPDATE_GROUP_SUCCESS,
            group: action.allGroups[grp],
          })
        }
      } else {
        if (action.allGroups[grp].members.indexOf(action.user.email) > -1) {
          action.allGroups[grp].members.splice(
            action.allGroups[grp].members.indexOf(action.user.email),
            1
          )
          yield call(apiActions.secureFetchSaga, {
            url: `${config.baseUrl}/api/permissions/groups/update`,
            init: {
              method: 'POST',
              body: JSON.stringify(action.allGroups[grp]),
            },
          })
          yield put({
            type: UPDATE_GROUP_SUCCESS,
            group: action.allGroups[grp],
          })
        }
      }
    }

    yield put({ type: appActions.COMPLETE_ASYNC })
    yield put({ type: modalActions.CLOSE_MODAL })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'An error occurred while editing user',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}
/*
 * Public facing action creator for the getGroups saga
 * This can be called from a modal or conent window, so takes an optional spinner param
 */
export const getGroups = (
  id = 'all',
  spinner = true,
  setPendingFlag = true
) => ({
  type: GET_GROUPS,
  id,
  spinner,
  setPendingFlag,
})

export function* watchGetGroups() {
  yield takeEvery(GET_GROUPS, getGroupsSaga)
}

export function* getGroupsSaga(action) {
  if (action.setPendingFlag)
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: action.spinner })
  try {
    const config = yield select((state) => state.config.appConfig)
    const groups = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/groups/${action.id}`,
      init: {
        method: 'GET',
      },
    })
    yield put({ type: GET_GROUPS_SUCCESS, groups })
    if (action.setPendingFlag) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log('get groups saga error', error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const updateGroup = (group) => ({
  type: UPDATE_GROUP,
  group,
})

export function* watchUpdateGroup() {
  yield takeEvery(UPDATE_GROUP, updateGroupSaga)
}

export function* updateGroupSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/groups/update`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.group),
      },
    })
    yield put({ type: UPDATE_GROUP_SUCCESS, group: action.group })
    yield put({ type: appActions.COMPLETE_ASYNC })
    yield put({ type: modalActions.CLOSE_MODAL })
  } catch (error) {
    console.log('update group saga error', error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const addGroup = (group) => ({
  type: ADD_GROUP,
  group,
})

export function* watchAddGroup() {
  yield takeEvery(ADD_GROUP, addGroupSaga)
}

export function* addGroupSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/groups/add`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.group),
      },
    })
    yield put({ type: ADD_GROUP_SUCCESS, group: action.group })
    yield put({ type: appActions.COMPLETE_ASYNC })
    yield put({ type: modalActions.CLOSE_MODAL })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'An error occurred while adding group',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const getRoles = (id = 'all', setPendingFlag = true) => ({
  type: GET_ROLES,
  id,
  setPendingFlag,
})

export function* watchGetRoles() {
  yield takeEvery(GET_ROLES, getRolesSaga)
}

export function* getRolesSaga(action) {
  if (action.setPendingFlag) yield put({ type: appActions.INITIATE_ASYNC })
  try {
    const config = yield select((state) => state.config.appConfig)
    const roles = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/roles/${action.id}`,
      init: {
        method: 'GET',
      },
    })
    yield put({ type: GET_ROLES_SUCCESS, roles })
    if (action.setPendingFlag) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const getPrivileges = (setPendingFlag = true) => ({
  type: GET_PRIVILEGES,
  setPendingFlag,
})

export function* watchGetPrivileges() {
  yield takeEvery(GET_PRIVILEGES, getPrivilegesSaga)
}

export function* getPrivilegesSaga(action) {
  if (action.setPendingFlag)
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const privileges = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/privileges`,
      init: {
        method: 'GET',
      },
    })

    yield put({ type: GET_PRIVILEGES_SUCCESS, privileges })
    if (action.setPendingFlag) yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const deleteGroup = (groupId) => ({
  type: DELETE_GROUP,
  groupId,
})

export function* watchDeleteGroup() {
  yield takeEvery(DELETE_GROUP, deleteGroupSaga)
}

export function* deleteGroupSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/permissions/groups/delete`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.groupId),
      },
    })
    yield put({ type: DELETE_GROUP_SUCCESS, groupId: action.groupId })
    const allFolders = yield select((state) => state.folders.byId)
    for (let folder of Object.keys(allFolders)) {
      if (
        allFolders[folder].contentRoles &&
        allFolders[folder].contentRoles[action.groupId]
      ) {
        let updatedContentRoles = allFolders[folder].contentRoles
        delete updatedContentRoles[action.groupId]
        yield call(folderActions.updateFolderSaga, {
          type: folderActions.UPDATE_FOLDER_CONTENTROLES,
          folder: allFolders[folder],
          path: null,
          allFolders: null,
          checkTraverseRole: false,
          contentRoles: updatedContentRoles,
        })
      }
    }
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log('delete group error')
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export const getAllPermissionsData = () => ({
  type: GET_ALL_PERMISSIONS,
})

export function* watchGetAllPermissions() {
  yield takeEvery(GET_ALL_PERMISSIONS, getAllPermissionsSaga)
}

export function* getAllPermissionsSaga() {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    yield all([
      call(getUsersSaga, {
        type: GET_USERS,
        setPendingFlag: false,
      }),
      call(getPrivilegesSaga, {
        type: GET_PRIVILEGES,
        setPendingFlag: false,
      }),
      call(getGroupsSaga, {
        type: GET_GROUPS,
        id: 'all',
        setPendingFlag: false,
      }),
      call(getRolesSaga, {
        type: GET_ROLES,
        id: 'all',
        setPendingFlag: false,
      }),
      call(invitationActions.getInvitationSaga, {
        type: invitationActions.GET_INVITATIONS,
        id: 'all',
        setPendingFlag: false,
      }),
    ])
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

export default all([
  watchGetUsers(),
  watchDeleteUser(),
  watchEditUser(),
  watchGetGroups(),
  watchUpdateGroup(),
  watchAddGroup(),
  watchGetRoles(),
  watchGetPrivileges(),
  watchDeleteGroup(),
  watchGetAllPermissions(),
])
