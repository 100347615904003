import { message } from 'antd'
import { get } from 'lodash'
import md5 from 'md5'
import moment from 'moment'
import React from 'react'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery,
} from 'redux-saga/effects'
import * as apiActions from './api'
import * as appActions from './appActions'
import * as builderActions from './builders'
import * as modalActions from './modal'
import * as userActions from './user'
import { prepComparisonDataForTable } from '../../components/builders/main/utils'
import * as analyticsConstants from '../constants/analytics'
import {
  TRANSCODING_STATUS_PENDING,
  UGC_ACCEPTED_STATUS,
} from '../constants/assets'
import { BUILDER_TYPES, COMPARISON__BUILDER_TYPE } from '../constants/builders'
import { UGC_FOLDER } from '../constants/folder'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import {
  assetIsPublished,
  assetIsReferenced,
  buildPath,
  getFileDetails,
  getFileExtension,
  recordAnalyticsEvent,
  validateSysTags,
} from '../modules/utils'
import helpers from './utils/helpers'
//Asset action types
export const LOAD_ASSET_STATE = 'LOAD_ASSET_STATE'

export const BULK_DOWNLOAD = 'asset/BULK_DOWNLOAD'
export const CREATE_ASSET = 'CREATE_ASSET'
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS'
export const REPLACE_ASSET = 'REPLACE_ASSET'
export const REPLACE_ASSET_SUCCESS = 'REPLACE_ASSET_SUCCESS'
export const CANCEL_REPLACE_ASSET = 'CANCEL_REPLACE_ASSET'
export const CREATE_USERASSET = 'CREATE_USERASSET'
export const CREATE_USERASSET_SUCCESS = 'CREATE_USERASSET_SUCCESS'
export const GET_ASSET = 'GET_ASSET'
export const GET_ASSET_SUCCESS = 'GET_ASSET_SUCCESS'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS'
export const CROP_ASSET = 'CROP_ASSET'
export const DROP_ASSET = 'DROP_ASSET'
export const DROP_ASSET_REQUEST = 'DROP_ASSET_REQUEST'
export const DROP_ASSET_SUCCESS = 'DROP_ASSET_SUCCESS'
export const DROP_ASSET_FAILURE = 'DROP_ASSET_FAILURE'
export const CLONE_ASSET = 'CLONE_ASSET'
export const CLONE_ASSET_SUCCESS = 'CLONE_ASSET_SUCCESS'
export const BULK_EDIT = 'BULK_EDIT'
export const GET_SECURE_LINKS = 'GET_SECURE_LINKS'
export const GET_SECURE_LINKS_SUCCESS = 'GET_SECURE_LINKS_SUCCESS'
export const CLEAR_SECURE_LINKS = 'CLEAR_SECURE_LINKS'
export const PARSE_COMPARISON_DATA_REQUEST = 'PARSE_COMPARISON_DATA_REQUEST'
export const PARSE_COMPARISON_DATA = 'PARSE_COMPARISON_DATA'
export const DELETE_INVALID_COMPARISON_FILE = 'DELETE_INVALID_COMPARISON_FILE'
export const REVIEW_UGC_ASSET = 'REVIEW_UGC_ASSET'

export function bulkDownload(assets) {
  return {
    type: BULK_DOWNLOAD,
    assets,
  }
}

export function* watchBulkDownload() {
  yield takeEvery(BULK_DOWNLOAD, bulkDownloadSaga)
}

function* bulkDownloadPoll(downloadId) {
  const config = yield select((state) => state.config.appConfig)

  while (true) {
    try {
      const result = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/bulkdownload/${downloadId}`,
        init: { method: 'GET' },
      })

      if (result.url) {
        yield put(
          appActions.setBulkDownloadLink(`${config.baseUrl}/${result.url}`)
        )
        yield put({ type: 'POLL_END' })
      }

      yield delay(5000)
    } catch (err) {
      console.error({ err })
      yield put({ type: 'POLL_END' })
    }
  }
}

export function* bulkDownloadSaga(action) {
  const config = yield select((state) => state.config.appConfig)

  try {
    yield put({ type: appActions.INITIATE_ASYNC })

    const byId = yield select((state) => state.assets.byId)
    const builderAssets = action.assets.filter((id) =>
      BUILDER_TYPES.includes(byId[id].asset_type)
    )

    if (builderAssets.length === action.assets.length) {
      yield put(
        modalActions.setMessage({
          type: 'WARNING',
          msg:
            'Your selection of builder type assets cannot be downloaded. These asset types are ' +
            'not viewable outside this platform.',
        })
      )
      yield put(modalActions.openModal('MessageModal'))
    } else {
      if (builderAssets.length > 0) {
        yield put(
          modalActions.openModal('BulkDownloadModal', {
            warning:
              'Note: your selection includes builder type assets. These assets will be excluded ' +
              'from the download as they are not viewable outside this platform.',
          })
        )
      } else {
        yield put(modalActions.openModal('BulkDownloadModal'))
      }

      const result = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/bulkdownload`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            assetIds: action.assets.filter(
              (id) => !BUILDER_TYPES.includes(byId[id].asset_type)
            ),
          }),
        },
      })

      if (result.success) {
        let polling = true

        while (polling) {
          const { end, modalClose } = yield race({
            poll: call(bulkDownloadPoll, result.id),
            end: take('POLL_END'),
            modalClose: take(modalActions.CLOSE_MODAL),
          })

          if (end || modalClose) {
            polling = false
            yield cancel()
          }
        }
      }
    }
  } catch (err) {
    if (err instanceof SessionTimeoutError) {
      yield put(userActions.logout())
    } else {
      console.error('bulk download error', err)

      yield put(
        modalActions.setMessage({
          type: 'ERROR',
          msg:
            err.result.clientMessage ||
            'An error occurred during bulk download',
        })
      )
      yield put(modalActions.openModal('MessageModal'))
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 * createAsset = public facing action creator for upload Assets
 */
export function createAsset(asset, parent, token, userKey, bulk) {
  return {
    type: CREATE_ASSET,
    asset,
    parent,
    token,
    userKey,
    bulk,
  }
}

/*
 * watchUploadAsset = watcher to initiate createAssetSaga
 */
export function* watchCreateAsset() {
  yield takeEvery(CREATE_ASSET, createAssetSaga)
}

/*
 * createAssetSaga - async function to handle uploading assets.
 *  PUTs INITIATE_ASYNC to notify UI that async action is in progress
 *  Assigns a hash ID, timestamps, deleted flag and asset_type
 *  CALLs lambda to retrieve metadata (mime-type, size, ETAG)
 *  CALLs the createAssets function through API = this function adds the asset to dynamoDB
 *   and adds a record to the Actions table
 *  On success, it:
 *   PUTs CREATE_ASSET_SUCCESS to notify the UI that async is done
 *   PUTs CLOSE_MODAL to close the upload modal
 *  On failure, it:
 *   PUTs CREATE_ASSET_FAILURE to notify the UI that async is done
 *   PUTs SET_MESSAGE to set a modal error message
 *   PUTs OPEN_MODAL to display the error
 */
export function* createAssetSaga(action) {
  let step = 'STEP_0'

  if (!action.bulk) {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: false })
  }

  const config = yield select((state) => state.config.appConfig)

  try {
    let data
    // Refresh state for single uploads
    if (!action.bulk) {
      data = yield call(appActions.refreshStateSaga)
      // Bulk uploads have already refreshed the state
    } else {
      data = yield select((state) => ({
        folderState: state.folders,
        assetState: state.assets,
        endpointState: state.endpoints,
      }))
    }

    // Make sure parent folder hasn't been deleted
    if (data.folderState.byId[action.parent]) {
      const timestamp = Date.now()

      // Hash the url and timestamp for 'unique id' - add required metadata
      action.asset.id = md5(`${action.asset.url}${timestamp}`)
      action.asset.created = timestamp
      action.asset.updated = timestamp
      action.asset.deleted = false
      action.asset.version = 1
      action.asset.publishedTo = []
      action.asset.extension = getFileExtension(action.asset.filename)
      // Default asset_type
      if (!action.asset.asset_type) action.asset.asset_type = 'asset'
      // Retrieve metadata of the file
      step = 'STEP_1'
      const metadata = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/metadata`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset: action.asset,
            operation: 'create',
          }),
        },
      })
      step = 'STEP_2'
      //Set key & url based on response - ensures no duplicate uploads
      action.asset.key = metadata.key
      action.asset.url = `${config.baseUrl}/${action.asset.key}`

      action.asset.ETag = metadata.ETag
      action.asset.size = metadata.size

      //Set preview icon based on MimeType (unless its a news or feature item that already has
      // a preview)
      if (
        !(
          action.asset.preview !== '' &&
          (action.asset.asset_type === 'news' ||
            action.asset.asset_type === 'feature')
        )
      ) {
        const details = getFileDetails(
          metadata.type,
          action.asset.url,
          action.asset.extension
        )
        action.asset.preview = details.preview
        // Check for pdf preview
        if (
          action.asset.extension === 'pdf' ||
          metadata.type === 'application/pdf'
        ) {
          const result = yield call(apiActions.secureFetchSaga, {
            url: `${config.baseUrl}/api/assets/pdfimage`,
            init: {
              method: 'POST',
              body: JSON.stringify(action.asset.key),
            },
          })

          if (result.success) {
            action.asset.preview = `${config.baseUrl}/${result.previewAsset}`
          }
        }

        action.asset.color = details.color
        action.asset.icon = details.icon
      }
      action.asset.file_type = metadata.type ? metadata.type : ''
      // Set transcoding status
      if (action.asset.file_type.startsWith('video')) {
        action.asset.videoSrc = metadata.videoSrc
        action.asset.preview = metadata.preview
      }
      // If this is an image, get it's dimensions and color space
      // set to null in case of error
      action.asset.dimensions = null
      if (
        action.asset.icon === 'fa-file-image-o' ||
        action.asset.file_type.startsWith('image')
      ) {
        // Dimensions
        const size = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/imgx/size`,
          init: {
            method: 'POST',
            body: JSON.stringify(action.asset.key),
          },
        })
        if (!size.error) {
          action.asset.dimensions = size
        }
        // Color Space
        const colorSpace = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/imgx/color`,
          init: {
            method: 'POST',
            body: JSON.stringify(action.asset.key),
          },
        })
        if (!colorSpace.error) {
          action.asset.imageAttributes = colorSpace
        }
      }

      // Add SYS tags for missing metadata
      if (action.asset.tags.length === 0) {
        action.asset.tags.push('SYS.missingtags')
      }

      if (
        action.asset.title.length === 0 &&
        action.asset.tags.indexOf('SYS.missingtitle') < 0
      ) {
        action.asset.tags.push('SYS.missingtitle')
      }

      if (
        action.asset.description.length === 0 &&
        action.asset.tags.indexOf('SYS.missingdescription') < 0
      ) {
        action.asset.tags.push('SYS.missingdescription')
      }

      // Call API to store the initial asset state and action
      const url = `${config.baseUrl}/api/assets/create/`
      const user = yield select((state) => state.user)
      const event = {
        event_type: 'CREATE_ASSET',
        target_id: action.asset.id,
        user: user.id,
        timestamp,
        attributes: Object.keys(action.asset).map((key) => ({
          name: key,
          new_value: action.asset[key],
          old_value: null,
        })),
      }

      yield call(apiActions.secureFetchSaga, {
        url,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset: action.asset,
            parent: action.parent,
            userKey: action.userKey,
            action,
            event,
          }),
        },
      })

      step = 'STEP_4'
      yield put({
        type: CREATE_ASSET_SUCCESS,
        asset: action.asset,
        parent: action.parent,
      })

      step = 'STEP_5'
      if (!action.bulk) {
        yield put({ type: modalActions.CLOSE_MODAL })
      }

      yield spawn(recordAnalyticsEvent, action.type, { asset: action.asset })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'The folder you tried to upload to no longer exists.  The application has been ' +
            'refreshed - please select a new folder and try again.',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // Already handled
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log('create asset error', step, error)

      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.result.clientMessage ||
            'An error occurred during asset creation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    if (!action.bulk) {
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

/*
 * saveAssetEdits - public facing action creator for saving asset edits
 */
export function saveAssetEdits(
  asset,
  updates,
  refreshState = true,
  replaceAsset = false,
  previousEtag = null
) {
  return {
    type: UPDATE_ASSET,
    asset,
    updates,
    refreshState,
    replaceAsset,
    previousEtag,
  }
}

/*
 * watchSaveAssetEdits - watcher to initiate the saveAssetEdits saga
 */
export function* watchSaveAssetEdits() {
  yield takeEvery(UPDATE_ASSET, saveAssetEditsSaga)
}

/*
 * saveAssetEditsSaga - function to update asset metadata
 *  PUTs an UPDATE_ASSET_REQUEST
 *  CALLs API to add record to Actions table
 *  On success - it PUTs an UPDATE_ASSET_SUCCESS and CLOSE_MODAL
 *  On failure - it PUTs an UPDATE_ASSET_FAILURE and SET_MESSAGE/OPEN_MODAL
 *   to display the error.
 */
export function* saveAssetEditsSaga(action) {
  const { asset, updates, refreshState, replaceAsset, previousEtag } = action

  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const timestamp = yield call(Date.now)
    const user = yield select((state) => state.user)
    const config = yield select((state) => state.config.appConfig)
    const adictAllAssets = yield select((state) => state.assets.byId)

    const stateData = refreshState
      ? yield call(appActions.refreshStateSaga)
      : null

    const assetExistsInState = refreshState
      ? get(stateData, `assetState.byId.${asset.id}`, null)
      : get(adictAllAssets, `${asset.id}`, null)

    let modifiedAction = action
    if (assetExistsInState) {
      modifiedAction.user = user.id
      modifiedAction.client = user.client
      modifiedAction.updates = {
        ...modifiedAction.updates,
        updated: timestamp,
        updated_by: user.id,
      }

      // Check for SYS tag updates
      if (updates.tags)
        modifiedAction.updates = validateSysTags(modifiedAction.updates)

      let events = [
        {
          event_type: UPDATE_ASSET,
          target_id: asset.id,
          timestamp,
          user: user.id,
          attributes: Object.keys(modifiedAction.updates).map((key) => ({
            name: key,
            new_value: modifiedAction.updates[key],
            old_value: modifiedAction.asset[key],
          })),
        },
      ]

      let metadata = null
      // On a replacement, we need to update the <stage>Assets table ETag references
      if (replaceAsset) {
        metadata = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/assets/metadata`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              asset,
              operation: 'replace',
              previousEtag,
            }),
          },
        })
      }
      // If video transcoding is pending, check to see if it finished while the edit was ongoing
      // (might already have metadata...)
      if (
        asset.file_type.startsWith('video') &&
        asset.videoSrc &&
        asset.videoSrc.status === TRANSCODING_STATUS_PENDING
      ) {
        if (!metadata) {
          metadata = yield call(apiActions.secureFetchSaga, {
            url: `${config.baseUrl}/api/assets/metadata`,
            init: {
              method: 'POST',
              body: JSON.stringify({
                asset,
                operation: 'query',
              }),
            },
          })
        }
        if (metadata.videoSrc.status !== asset.videoSrc.status) {
          modifiedAction.updates = {
            ...modifiedAction.updates,
            preview: metadata.preview,
            videoSrc: {
              ...metadata.videoSrc,
            },
          }
        }
      }

      // Invalidate the public url
      // console.log("METADATA", metadata)
      // console.log("ASSET DATA", asset)
      // console.log("ASSET UPDATES", updates)
      // console.log("ASSET!!", adictAllAssets[asset.id])
      try {
        if (replaceAsset && asset.publicURL) {
          yield call(apiActions.secureFetchSaga, {
            url: `${config.baseUrl}/api/assets/invalidate`,
            init: {
              method: 'POST',
              body: JSON.stringify({
                asset: {
                  id: asset.id,
                  key: asset.key,
                },
                reason: 'replace',
              }),
            },
          })
        }
      } catch (err) {
        console.log(err)
      }

      const result = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction/`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action: modifiedAction, events }),
        },
      })

      if (result.statusCode && result.statusCode !== 200) {
        throw new Error(result.message)
      }
      yield put({
        type: UPDATE_ASSET_SUCCESS,
        asset,
        updates: modifiedAction.updates,
      })
      yield put({ type: appActions.SET_EDITMODE_ASSET, assetId: null })
      yield fork(recordAnalyticsEvent, modifiedAction.type, { asset })
      yield put({ type: modalActions.CLOSE_MODAL })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'The asset you tried to edit to no longer exists.  The application has been ' +
            'refreshed.',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.error(error)

      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.message || 'Unable to save edits, please try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 *  Replace underlying asset file in local state.
 *  If user saves the changes, they will be applied to cloud state
 */
export const replaceAsset = (originalAsset) => ({
  type: REPLACE_ASSET,
  originalAsset,
})

export function* watchReplaceAsset() {
  yield takeEvery(REPLACE_ASSET, replaceAssetSaga)
}

export function* replaceAssetSaga(action) {
  const appState = yield select((state) => ({
    upload: state.appState.successfulUploads[0],
    editModeAsset: state.appState.editModeAsset,
  }))

  if (appState.editModeAsset !== action.originalAsset.id)
    yield put({
      type: appActions.SET_EDITMODE_ASSET,
      assetId: action.originalAsset.id,
    })

  let step = 'STEP_0'
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  const config = yield select((state) => state.config.appConfig)

  try {
    const data = yield call(appActions.refreshStateSaga)
    // Make sure parent folder hasn't been deleted
    if (helpers.validateReplaceAsset(action, data)) {
      const timestamp = yield call(Date.now)
      const user = yield select((state) => state.user)

      // Copy the original asset
      const replacementAsset = {
        ...action.originalAsset,
        filename: appState.upload.filename,
        key: appState.upload.key,
        size: appState.upload.size,
        url: appState.upload.url,
        updated: timestamp,
        updated_by: user.id,
      }

      replacementAsset.extension = yield call(
        getFileExtension,
        replacementAsset.filename
      )
      const metadata = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/metadata`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset: replacementAsset,
            operation: 'query',
          }),
        },
      })

      // If underlying asset is replaced...it's no longer a UGC item.
      replacementAsset.tags = action.originalAsset.tags.filter(
        (tag) => tag !== 'SYS.ugc'
      )

      step = 'STEP_2'
      //Set key & url based on response - ensures no duplicate uploads
      replacementAsset.key = metadata.key
      replacementAsset.url = `${config.baseUrl}/${replacementAsset.key}`

      replacementAsset.ETag = metadata.ETag
      replacementAsset.size = metadata.size

      //Set preview icon based on MimeType (unless its a news or feature item that already
      //has a preview)
      if (
        replacementAsset.preview === '' ||
        (replacementAsset.asset_type !== 'news' &&
          replacementAsset !== 'feature')
      ) {
        const details = yield call(
          getFileDetails,
          metadata.type,
          replacementAsset.url,
          replacementAsset.extension
        )
        replacementAsset.preview = details.preview
        replacementAsset.color = details.color
        replacementAsset.icon = details.icon
      }

      replacementAsset.file_type = metadata.type || ''

      // Check for pdf preview
      if (
        replacementAsset.extension === 'pdf' ||
        metadata.type === 'application/pdf'
      ) {
        const result = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/assets/pdfimage`,
          init: {
            method: 'POST',
            body: JSON.stringify(replacementAsset.key),
          },
        })

        if (result.success) {
          replacementAsset.preview = `${config.baseUrl}/${result.previewAsset}`
        }
      }

      // If it's a video, set videoSrc.  If it's not, make sure videoSrc is not populated
      if (metadata.type.startsWith('video')) {
        replacementAsset.videoSrc = metadata.videoSrc
        replacementAsset.preview = metadata.preview
      } else {
        replacementAsset.videoSrc = {}
      }

      // If this is an image, get it's dimensions
      // set to null in case of error
      replacementAsset.dimensions = null
      if (
        replacementAsset.icon === 'fa-file-image-o' ||
        replacementAsset.file_type.startsWith('image')
      ) {
        // Dimensions
        const size = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/imgx/size`,
          init: {
            method: 'POST',
            body: JSON.stringify(replacementAsset.key),
          },
        })
        if (!size.error) {
          replacementAsset.dimensions = size
        }
        // Color Space
        const colorSpace = yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/imgx/color`,
          init: {
            method: 'POST',
            body: JSON.stringify(replacementAsset.key),
          },
        })
        if (!colorSpace.error) {
          replacementAsset.imageAttributes = colorSpace
        }
      }

      step = 'STEP_4'
      yield put({
        type: REPLACE_ASSET_SUCCESS,
        originalAsset: action.originalAsset,
        replacementAsset,
      })

      step = 'STEP_5'
      yield put({ type: modalActions.CLOSE_MODAL })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'The folder you tried to upload to no longer exists.  The application has been ' +
            'refreshed - please select a new folder and try again.',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // Already handled
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.error(step, error)
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.result && error.result.clientMessage
              ? error.result.clientMessage
              : 'An error occurred during asset creation',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export const cancelReplaceAsset = () => ({
  type: CANCEL_REPLACE_ASSET,
})

/*
 * cropAsset - public facting action creator
 */
export function cropAsset(crop, asset) {
  return {
    type: CROP_ASSET,
    crop,
    asset,
  }
}

/*
 * watchCropAsset - saga initiator for Crop Asset saga
 */
export function* watchCropAsset() {
  yield takeEvery(CROP_ASSET, cropAssetSaga)
}

/*
 * cropAssetSaga - set of actions to save a cropped asset
 */
export function* cropAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const data = yield call(appActions.refreshStateSaga)
    if (data.assetState.byId[action.asset.id]) {
      // Check the user session
      const user = yield select((state) => state.user)

      action.user = user.id
      action.client = user.client
      const result = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/imgx/crop`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            crop: action.crop,
            asset: action.asset,
            user: action.user,
          }),
        },
      })
      yield put({
        type: UPDATE_ASSET_SUCCESS,
        asset: action.asset,
        updates: result,
      })
      yield put({ type: appActions.COMPLETE_ASYNC })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: `Unable to crop asset - the asset was probably deleted by another user.
                The application has been refreshed, please check the asset and try again.`,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log(error)
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Unable to crop asset, please try again',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

/*
 * createUserAsset - public facing action creator
 */
export function createUserAsset(asset, userKey) {
  return {
    type: CREATE_USERASSET,
    asset,
    userKey,
  }
}

/*
 * watchCreateUserAsset - watcher function to initiate saga
 */
export function* watchCreateUserAsset() {
  yield takeEvery(CREATE_USERASSET, createUserAssetSaga)
}

/*
 * createUserAssetSaga - saga to create user owned assets (i.e. profile pics)
 *   PUT CREATE_USERASSET_REQUEST
 *   CALL the createUserAsset API
 */
export function* createUserAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: false })
  try {
    const config = yield select((state) => state.config.appConfig)
    const timestamp = yield call(Date.now)

    // Hash the url and timestamp for 'unique id' - add required metadata
    action.asset.id = md5(`${action.asset.url}${timestamp}`)
    action.asset.created = timestamp
    action.asset.updated = timestamp
    action.asset.deleted = false
    action.asset.version = 1
    action.asset.publishedTo = []
    action.asset.extension = ''
    // Check the mime type of the file
    const metadata = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/assets/metadata`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          asset: action.asset,
          operation: 'create',
        }),
      },
    })

    //Set key & url based on response - ensures no duplicate uploads
    action.asset.key = metadata.key
    action.asset.url = `${config.baseUrl}/${action.asset.key}`

    action.asset.ETag = metadata.ETag
    action.asset.size = metadata.size
    //Set preview icon based on MimeType
    const details = getFileDetails(
      metadata.type,
      action.asset.url,
      action.asset.extension
    )
    action.asset.preview = metadata.preview || details.preview
    action.asset.color = details.color
    action.asset.icon = details.icon
    action.asset.file_type = metadata.type ? metadata.type : ''

    const res = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/assets/create`,
      init: {
        method: 'POST',
        body: JSON.stringify({
          asset: action.asset,
          userKey: action.userKey,
          action,
          event: null,
        }),
      },
    })
    yield put({ type: CREATE_USERASSET_SUCCESS, preview: res.asset.preview })
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: 'Failed to create asset' },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

/*
 *   dropAsset - action dispatched when an asset is dropped via drag-n-drop
 */
export function dropAsset(dropId, dragId, sourceFolder) {
  return {
    type: DROP_ASSET,
    dropId,
    dragId,
    sourceFolder,
  }
}

/*
 * watchDropAsset = watcher function to initiate the drop asset saga
 */
export function* watchDropAsset() {
  yield takeEvery(DROP_ASSET, dropAssetSaga)
}

/*
 * dropAssetSaga - saga to process a drag-n-drop operation on asset
 */
function* dropAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })

  try {
    const config = yield select((state) => state.config.appConfig)
    // Check the user session
    const user = yield select((state) => state.user)

    action.user = user.id
    action.client = user.client

    const data = yield call(appActions.refreshStateSaga)
    let valid = true
    let msg = ''

    //validate that state is still valid for this drop
    action.dragId.forEach((asset) => {
      const folder = data.folderState.byId[action.sourceFolder]

      // if the folder does not contain the asset
      // or if a UGC folder, check virtual child folders
      if (
        !folder.assets.includes(asset) &&
        !(
          action.sourceFolder === UGC_FOLDER &&
          folder.childFolders.some((vf) =>
            data.folderState.byId[vf].assets.includes(asset)
          )
        )
      ) {
        valid = false
        msg = `Asset move is not valid. The assets were probably changed by another user.
                The application has been refreshed, please check the assets and try again.`
      }

      const assetPath = buildPath(action.dropId, data.folderState.byId)

      if (assetPath.indexOf(data.folderState.recycleBin) > -1) {
        if (
          assetIsPublished(
            asset,
            data.assetState.relationships.assets,
            data.assetState.byId
          )
        ) {
          valid = false
          msg =
            'Published Assets may not be deleted until they are unpublished from all folders'
        } else if (
          assetIsReferenced(asset, data.assetState.relationships.assets)
        ) {
          valid = false
          msg = 'Assets that are referenced by other assets may not be deleted.'
        }
      }
    })

    if (valid) {
      const timestamp = yield call(Date.now)
      let events = []

      action.dragId.forEach((asset) => {
        events.push({
          event_type: DROP_ASSET,
          target_id: asset,
          user: action.user,
          timestamp,
          attributes: {
            name: 'folder',
            old_value: action.sourceFolder,
            new_value: action.dropId,
          },
        })
      })

      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })
      yield put({
        type: DROP_ASSET_SUCCESS,
        dropId: action.dropId,
        dragId: action.dragId,
        sourceFolder: action.sourceFolder,
      })

      yield put({ type: modalActions.CLOSE_MODAL })
    } else {
      yield put({ type: DROP_ASSET_FAILURE, msg })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: msg,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: DROP_ASSET_FAILURE, error })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.message || 'Unable to move asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    yield put({ type: appActions.CLEAR_SELECTED_ASSETS })
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export function cloneAsset(assetToClone, parentId) {
  return {
    type: CLONE_ASSET,
    assetToClone,
    parentId,
  }
}

export function* watchCloneAsset() {
  yield takeEvery(CLONE_ASSET, cloneAssetSaga)
}

export function* cloneAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const data = yield call(appActions.refreshStateSaga)
    //parent folder exists and contains this asset...
    if (
      data.folderState.byId[action.parentId] &&
      data.folderState.byId[action.parentId].assets.indexOf(
        action.assetToClone.id
      ) > -1
    ) {
      const user = yield select((state) => state.user)
      const timestamp = yield call(Date.now)
      const clone = {
        ...action.assetToClone,
        id: md5(`${action.assetToClone.id}clone${timestamp}`),
        owner: user.id,
        created: timestamp,
        deleted: false,
        version: 1,
        updated: timestamp,
        publishedTo: [],
        referencedBy: [],
        publicURL: undefined,
      }
      action.user = user.id
      action.client = user.client
      action.clone = clone
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/clone`,
        init: {
          method: 'POST',
          body: JSON.stringify(action),
        },
      })
      let events = [
        {
          event_type: CLONE_ASSET,
          target_id: action.clone.id,
          user: action.user,
          timestamp,
          attributes: {
            name: 'cloned_from',
            old_value: null,
            new_value: action.assetToClone,
          },
        },
        {
          event_type: CLONE_ASSET,
          target_id: action.assetToClone.id,
          user: action.user,
          timestamp,
          attributes: {
            name: 'cloned_to',
            old_value: null,
            new_value: action.clone.id,
          },
        },
      ]
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/state/addaction`,
        init: {
          method: 'POST',
          body: JSON.stringify({ action, events }),
        },
      })
      yield put({
        type: CLONE_ASSET_SUCCESS,
        clone: action.clone,
        assetToClone: action.assetToClone,
        parentId: action.parentId,
      })
      yield put({ type: appActions.COMPLETE_ASYNC })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: `Asset cannot be cloned - it may have been changed or deleted by another user.
                The application has been refreshed, please check the assets and try again.`,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage || 'An error occurred while cloning this asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export function bulkEdit(assets, metadata, parentId, create) {
  return {
    type: BULK_EDIT,
    assets,
    metadata,
    parentId,
    create,
  }
}

export function* watchBulkEdit() {
  yield takeEvery(BULK_EDIT, bulkEditSaga)
}

export function* bulkEditSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC })

  const datestring = moment().format('YYYYMMDD')
  let counter = 1

  try {
    const data = yield call(appActions.refreshStateSaga)

    if (helpers.validateBulkEditRequest(action, data)) {
      const requests = []

      for (let asset of action.assets) {
        if (action.create) {
          asset.title = action.metadata.title
            .replace('%F', asset.filename)
            .replace('%D', datestring)
            .replace('%I', counter)
          asset.description = action.metadata.description
            .replace('%F', asset.filename)
            .replace('%D', datestring)
            .replace('%I', counter)
          asset.tags = action.metadata.tags

          requests.push(
            call(createAssetSaga, {
              type: CREATE_ASSET,
              asset,
              parent: action.parentId,
              bulk: true,
            })
          )
        } else {
          const updates = {}

          if (action.metadata.title.length > 0) {
            updates.title = action.metadata.title
              .replace('%F', asset.filename)
              .replace('%D', datestring)
              .replace('%I', counter)
          }

          if (action.metadata.description.length > 0) {
            updates.description = action.metadata.description
              .replace('%F', asset.filename)
              .replace('%D', datestring)
              .replace('%I', counter)
          }

          const tags = new Set(
            asset.tags.concat(
              action.metadata.tags.filter((tag) => {
                let duplicate = false
                asset.tags.forEach((assetTag) => {
                  if (assetTag.toLowerCase() === tag.toLowerCase())
                    duplicate = true
                })
                return !duplicate
              })
            )
          )

          updates.tags = Array.from(tags)

          requests.push(
            call(saveAssetEditsSaga, {
              type: UPDATE_ASSET,
              asset,
              updates,
            })
          )
        }

        counter = counter + 1
      }

      yield all(requests)

      yield all([
        put({ type: appActions.CLEAR_SELECTED_ASSETS }),
        put(modalActions.closeModal()),
      ])
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            'One of the assets you tried to edit (or its parent folder) no longer exists.  ' +
            'The application has been refreshed.',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg:
            error.clientMessage || 'An error occurred while editing this asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

/*
 *  Get latest asset details from State API.
 *  If requested, the action records an ASSET_DETAIL_VIEW event
 */
export const getAsset = (asset, recordEvent = false) => ({
  type: GET_ASSET,
  asset,
  recordEvent,
})

export function* watchGetAsset() {
  yield takeEvery(GET_ASSET, getAssetSaga)
}

export function* getAssetSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  try {
    const config = yield select((state) => state.config.appConfig)
    const result = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/state/asset/current`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.asset),
      },
    })
    yield put({
      type: GET_ASSET_SUCCESS,
      asset: result.asset,
      folderAssetStatus: result.folderAssetStatus,
      relationships: result.relationships,
    })
    if (action.recordEvent) {
      const user = yield select((state) => state.user)
      yield fork(recordAnalyticsEvent, action.type, {
        asset: result.asset,
        user,
      })
    }

    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      console.log(error)
      yield put({ type: appActions.COMPLETE_ASYNC })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type: 'ERROR',
          msg: error.clientMessage || 'Unable to get asset',
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
  }
}

export const clearSecureLinks = () => ({
  type: CLEAR_SECURE_LINKS,
})

export const getSecureLinks = (assets, user, duration) => ({
  type: GET_SECURE_LINKS,
  assets: Array.isArray(assets) ? assets : [assets],
  user,
  duration,
})

export function* watchGetSecureLinks() {
  yield takeEvery(GET_SECURE_LINKS, getSecureLinksSaga)
}

export function* getSecureLinksSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    const config = yield select((state) => state.config.appConfig)
    const results = []

    let assets = action.assets
    // convert ids to objects as necessary
    if (typeof assets[0] === 'string') {
      const assetsById = yield select((state) => state.assets.byId)
      assets = action.assets.map((assetId) => assetsById[assetId])
    }

    for (let asset of assets) {
      const result = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/share`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset,
            user: action.user,
            duration: action.duration,
          }),
        },
      })
      yield fork(recordAnalyticsEvent, analyticsConstants.SHARE_ASSET, {
        asset: asset,
        url: result,
      })
      results.push({ url: result.url })
    }

    yield put({
      type: GET_SECURE_LINKS_SUCCESS,
      links: results,
      duration: action.duration,
    })

    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    } else {
      yield put({ type: modalActions.CLOSE_MODAL })
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: { type: 'ERROR', msg: 'Unable to share this asset.' },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
      yield put({ type: appActions.COMPLETE_ASYNC })
    }
  }
}

const DEFAULT_PUBLIC_LINK = 'bam.bam.bam'

export function* publicLinkSaga(action) {
  const { asset, user, revoke } = action
  if (!asset || !user)
    throw new Error(
      'An asset and a user need to be defined to generate a public url.'
    )

  try {
    const config = yield select((state) => state.config.appConfig)

    let publicURL

    if (revoke) {
      publicURL = null
      try {
        yield call(apiActions.secureFetchSaga, {
          url: `${config.baseUrl}/api/assets/invalidate`,
          init: {
            method: 'POST',
            body: JSON.stringify({
              asset: {
                id: asset.id,
              },
              reason: 'unpublish',
            }),
          },
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      // Fetch the public URL
      const createURLResult = yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/assets/sharePublic`,
        init: {
          method: 'POST',
          body: JSON.stringify({
            asset,
            user,
          }),
        },
      })

      // Update the asset with the public URL
      publicURL = get(createURLResult, 'url', DEFAULT_PUBLIC_LINK)
    }

    // Update the asset data with the public url
    yield put(saveAssetEdits(asset, { publicURL }, false))
  } catch (error) {
    const errorMessage =
      typeof error === 'object' ? JSON.stringify(error) : error
    throw new Error(errorMessage)
  }
}

export const parseComparisonData = (
  datafileKey,
  onSelectImage,
  onDeleteImage,
  builderData,
  isRowEditing = () => {},
  setEditRow = () => {},
  showMessage = true
) => ({
  type: PARSE_COMPARISON_DATA_REQUEST,
  datafileKey,
  onSelectImage,
  onDeleteImage,
  builderData,
  isRowEditing,
  setEditRow,
  showMessage,
})

export function* watchParseComparisonData() {
  yield takeEvery(PARSE_COMPARISON_DATA_REQUEST, parseComparisonDataSaga)
}

export function* parseComparisonDataSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    const config = yield select((state) => state.config.appConfig)

    const parsedData = yield call(apiActions.secureFetchSaga, {
      url: `${config.baseUrl}/api/assets/comparison/parse`,
      init: {
        method: 'POST',
        body: JSON.stringify(action.datafileKey),
      },
    })
    // Conditional used when we're re-parsing a comparison object file
    // This is because the data structure changed after initial launch
    let msg = ''
    let type = ''
    if (action.showMessage) {
      if (
        parsedData &&
        parsedData.validFile &&
        parsedData.skippedLines.length > 0
      ) {
        type = 'WARNING'
        msg = (
          <div>
            <div>The Comparison Data file was uploaded</div>
            <div>
              Some lines were skipped because they did not contain data values
              for all items, or because they contained duplicate attribute
              values - line numbers:{' '}
            </div>
            <ul>
              {parsedData.skippedLines.map((line) => (
                <li key={line.lineNumber}>
                  {line.lineNumber}: {line.attribute}
                </li>
              ))}
            </ul>
          </div>
        )
      } else if (
        parsedData &&
        parsedData.validFile &&
        parsedData.skippedLines.length === 0
      ) {
        type = 'SUCCESS'
        msg = 'Comparison Data file uploaded successfully.'
      } else {
        type = 'ERROR'
        msg =
          'The file format did not match specifications.  Please upload a valid file.'
        if (parsedData.duplicateItems) {
          msg =
            'There are duplicate Item Names in the file.  Item names must be unique.'
        } else if (parsedData.missingItemColumn) {
          msg =
            `Missing Item Data in column ${parsedData.missingItemColumn}.  The file format ` +
            'is invalid.'
        } else if (
          parsedData.skippedLines.length ===
          parsedData.totalLines - 1
        ) {
          msg =
            'Item Names were not defined in the file.  Please Upload a valid file'
        }
        yield call(deleteInvalidComparisonFileSaga, {
          type: DELETE_INVALID_COMPARISON_FILE,
          datafileKey: action.datafileKey,
          config,
        })
      }
      yield put({
        type: modalActions.SET_MESSAGE,
        msg: {
          type,
          msg,
        },
      })
      yield put({ type: modalActions.OPEN_MODAL, modal: 'MessageModal' })
    }
    if (type !== 'ERROR') {
      // We're going to react-ify the column title, because I can't figure out how to
      // return React nodes from lambda
      const allAssets = yield select((state) => state.assets.byId)
      const antTableData = yield call(
        prepComparisonDataForTable,
        parsedData.antData.columns,
        parsedData.antData.dataSource,
        allAssets,
        action.onSelectImage,
        action.onDeleteImage,
        action.isRowEditing,
        action.setEditRow
      )
      // Validate primaryClassification value against new array
      let primaryClassification = action.builderData.primaryClassification
      if (
        parsedData.classifications.indexOf(
          action.builderData.primaryClassification
        ) < 0
      ) {
        primaryClassification = null
      }
      yield put({
        type: builderActions.SET_KEY_FOR_BUILDER,
        builderType: COMPARISON__BUILDER_TYPE,
        keyVal: {
          datafileKey: action.datafileKey,
          comparisonData: antTableData,
          comparisonObject: parsedData.data,
          classifications: parsedData.classifications,
          primaryClassification,
        },
      })
    }
    yield put({ type: appActions.COMPLETE_ASYNC })
  } catch (error) {
    console.log(error)
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export function* deleteInvalidComparisonFileSaga(action) {
  yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
  yield call(apiActions.secureFetchSaga, {
    url: `${action.config.baseUrl}/api/assets/comparison/delete`,
    init: {
      method: 'POST',
      body: JSON.stringify(action.datafileKey),
    },
  })
}

export const reviewUgcAssets = (assets, result, dropFolder, history) => ({
  type: REVIEW_UGC_ASSET,
  assets,
  result,
  dropFolder,
  history,
})

export function* watchReviewUgcAssets() {
  yield takeEvery(REVIEW_UGC_ASSET, reviewUgcAssetsSaga)
}

/*
 *
 */
export function* reviewUgcAssetsSaga(action) {
  try {
    yield put({ type: appActions.INITIATE_ASYNC, showSpinner: true })
    const config = yield select((state) => state.config.appConfig)
    const user = yield select((state) => state.user)

    const allAssets = yield select((state) => state.assets.byId)
    for (let asset of action.assets) {
      const item = {
        asset: allAssets[asset].id,
        user: allAssets[asset].owner,
      }
      const timestamp = yield call(Date.now)
      const update = {
        reviewed: timestamp,
        reviewer: user.email,
        result: action.result,
      }
      yield call(apiActions.secureFetchSaga, {
        url: `${config.baseUrl}/api/upload/update`,
        init: {
          method: 'POST',
          body: JSON.stringify({ item, update }),
        },
      })
    }
    // Now move the assets to destination (recycle bin if not approved)
    yield put(dropAsset(action.dropFolder, action.assets, UGC_FOLDER))

    const { success } = yield race({
      success: take(DROP_ASSET_SUCCESS),
      failure: take(DROP_ASSET_FAILURE),
    })

    if (success) {
      yield call(appActions.refreshStateSaga)

      if (action.history) {
        yield call(action.history.push, `/library/${UGC_FOLDER}`)
      }

      if (action.result === UGC_ACCEPTED_STATUS) {
        yield call(message.success, `${action.assets.length} assets accepted`)
      } else {
        yield call(message.error, `${action.assets.length} assets deleted`)
      }
    }
  } catch (error) {
    console.log(error)
    // This occurs when Session times out and user chooses to quit
    if (error instanceof SessionTimeoutError) {
      yield put({ type: userActions.LOGOUT })
    }
  } finally {
    yield put({ type: appActions.COMPLETE_ASYNC })
  }
}

export default all([
  fork(watchBulkDownload),
  watchCreateAsset(),
  watchSaveAssetEdits(),
  watchReplaceAsset(),
  watchDropAsset(),
  watchCreateUserAsset(),
  watchCropAsset(),
  watchCloneAsset(),
  watchBulkEdit(),
  watchGetAsset(),
  watchGetSecureLinks(),
  watchParseComparisonData(),
  watchReviewUgcAssets(),
])
