import LogRocket from 'logrocket'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './dux/actions'

import { configureAmplify } from './dux/modules/utils'
import indexReducer from './dux/reducers'
import 'antd/dist/antd.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import App from './components/App'
import coBrandInfo from './config/co-brand.config'
if (!window.crypto) window.crypto = window.msCrypto

//** REMOVE sensitive data from redux dev tools **//
const sanitizeState = (state) => {
  let sanitizedState = state
  // INVITATIONS
  if (state.invitations) {
    sanitizedState = {
      ...sanitizedState,
      invitations: undefined,
    }
  }

  // USER INFORMATION
  if (state.permissions && state.permissions.users) {
    sanitizedState = {
      ...sanitizedState,
      permissions: {
        ...sanitizedState.permissions,
        users: undefined,
        deletedUsers: undefined,
      },
    }
  }

  return sanitizedState
}
// ** END Remove ** //

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({
  stateSanitizer: sanitizeState,
  trace: process.env.NODE_ENV === 'development',
})

let store = createStore(
  indexReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()))
)
sagaMiddleware.run(rootSaga)

export default store

const root = document.getElementById('root')

function renderApp(config) {
  const mask = config.mask
  if (mask && coBrandInfo[mask]) {
    const { tabTitle, favicon } = coBrandInfo[mask]
    root.className = mask.split('.')[0]
    const icon = document.getElementById('tabicon')
    if (favicon) {
      icon.href = favicon
    }
    if (tabTitle) {
      document.title = tabTitle
    }
  }
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App config={config} />
      </BrowserRouter>
    </Provider>,
    root
  )
}

if (process.env.NODE_ENV === 'development') {
  const config = require('./config').default
  configureAmplify(config)
  renderApp(config)
  window.store = store
} else {
  fetch('/config.json')
    .then((res) => {
      res
        .json()
        .then((config) => {
          // override domain / bucket / etc. for custom domain mask
          if (config.domain !== window.location.hostname) {
            config.mask = window.location.hostname
          }

          // LogRocket
          if (config.logrocketEnabled) {
            LogRocket.init('z96pbc/bam', {
              console: {
                isEnabled: {
                  debug: false,
                  info: false,
                },
                shouldAggregateConsoleErrors: true,
              },
              network: {
                requestSanitizer: (request) => {
                  request.headers['x-amz-security-token'] = null
                  return request
                },
              },
              shouldCaptureIP: false,
              shouldDebugLog: ['dev', 'qa'].includes(config.stage),
            })
            LogRocket.reduxMiddleware({ stateSanitizer: sanitizeState })
          }

          configureAmplify(config)
          renderApp(config)
        })
        .catch((err) => console.log(err))
    })
    .catch((err) => console.log(err))
}
//registerServiceWorker()
