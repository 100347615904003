import { Checkbox, Icon, Popover } from 'antd'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../dux/actions/invitation'
import * as modalActions from '../../dux/actions/modal'
import * as permissionsActions from '../../dux/actions/permissions'
import { copySpanToClipboard, isAdmin } from '../../dux/modules/utils'
import FourOhFour from '../common/FourOhFour'
import LoadingModal from '../modals/LoadingModal'
import InvitationList from './InvitationList'
import InvTableActions from './InvTableActions'

class InvitationContainer extends React.Component {
  state = {
    visiblePopover: '',
    copiedText: null,
  }

  handleVisibleChange = (id) => {
    if (this.state.visiblePopover === id) id = null

    this.setState((prevState) => ({
      visiblePopover: id,
      copiedText: id === null ? null : prevState.copiedText,
    }))
  }

  setCopiedText = () => this.setState(() => ({ copiedText: 'Copied' }))

  componentWillMount() {
    this.props.permissionsActions.getGroups('all')
    this.props.actions.getInvitations('all')
  }

  onRefresh = () => {
    this.props.actions.getInvitations('all')
  }

  onAdd = () => {
    this.props.modalActions.openModal('AddInvitationModal', {
      groups: Object.keys(this.props.permissions.groups).map(
        (key) => this.props.permissions.groups[key]
      ),
      onSubmit: this.props.actions.addInvitation,
    })
  }

  render() {
    const admin = isAdmin(this.props.email, this.props.permissions.groups)
    const columns = [
      {
        title: 'Invitation Code',
        dataIndex: 'id',
        width: 135,
        className: 'bam__table-column bam__table-column-maxwidth135',
        render: (text) => (
          <div className="bam__invitation-link">
            <span>{text}</span>
            <span>
              <Popover
                visible={this.state.visiblePopover === text}
                onVisibleChange={() => this.handleVisibleChange(text)}
                title={'BAM! DAM Invite'}
                content={
                  <span>
                    <span id="webInvitationLink">
                      {this.props.baseUrl}/register/{text}
                    </span>{' '}
                    &nbsp;
                    <Icon
                      type="copy"
                      className="bam__invitation-link-icon"
                      onClick={() => {
                        this.setCopiedText()
                        copySpanToClipboard('webInvitationLink')
                      }}
                    />
                    &nbsp;
                    {this.state.copiedText}
                  </span>
                }
                trigger="click"
              >
                <Icon type="laptop" className="bam__invitation-link-icon" />
              </Popover>
            </span>
          </div>
        ),
      },
      {
        title: 'Invitation Name',
        dataIndex: 'name',
        width: 300,
        className: 'bam__table-column bam__table-column-maxwidth300',
      },
      {
        title: 'Valid',
        dataIndex: 'valid',
        className: 'bam__table-column bam__table-column-maxwidth70',
        width: 70,
        render: (record) => <Checkbox disabled={true} checked={record} />,
      },
      {
        title: 'Uses',
        className: 'bam__table-column bam__table-column-maxwidth80',
        width: 80,
        render: (text, record) => (
          <span>
            {record.registrations} / {record.uses}
          </span>
        ),
      },
      {
        title: 'Created Date',
        dataIndex: 'created',
        className: 'bam__table-column bam__table-column-maxwidth125',
        width: 125,
        render: (record) => (
          <span>{moment(record || Date.now()).format('MM/DD/YYYY')}</span>
        ),
      },
      {
        title: 'Groups',
        dataIndex: 'groups',
        width: 180,
        className: 'bam__table-column bam__table-column-maxwidth180',
      },
      {
        title: 'Actions',
        className: 'bam__table-column bam__table-column-maxwidth120',
        width: 120,
        render: (editable, record) => (
          <InvTableActions record={record} isAdmin={admin} />
        ),
      },
    ]
    return !this.props.appState.initializing && !admin ? (
      <FourOhFour />
    ) : this.props.appState.asyncPending ? (
      <LoadingModal />
    ) : (
      <InvitationList
        dataSource={this.props.invitations.invitations}
        columns={columns.map((column) => {
          if (column.title === 'Groups') {
            column.render = (text, record) =>
              record.groups && (
                <div className="permissions__group-list">
                  {record.groups.map((group) => (
                    <div key={group}>
                      {this.props.permissions.groups[group] &&
                        this.props.permissions.groups[group].name}
                    </div>
                  ))}
                </div>
              )
          }
          return column
        })}
        refresh={this.onRefresh}
        add={this.onAdd}
        tablesize={this.props.appState.windowWidth < 1200 ? 'middle' : null}
        height={this.props.appState.windowHeight}
        isAdmin={admin}
        scroll={{ x: 1025, y: this.props.windowHeight - 230 }}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  baseUrl: state.config.appConfig.baseUrl,
  invitations: state.invitations,
  permissions: state.permissions,
  appState: state.appState,
  email: state.user.email,
})
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  modalActions: bindActionCreators(modalActions, dispatch),
  permissionsActions: bindActionCreators(permissionsActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(InvitationContainer)
