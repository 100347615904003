import React, { Component, createRef } from 'react'
import styles from './styles'
import { SlideImage } from './Components/Slide'
import { AssetList } from './Components/AssetList'
import { HOTSPOT__SIZE } from '../../../../dux/constants/builders'
export default class ProductPreview extends Component {
  constructor(props) {
    super(props)
    this.mainViewerRef = createRef()
    this.state = {
      currentIndex: 0,
      currentSelectedHotspot: null,
    }
  }

  componentDidMount() {
    /* Resize Observer web API https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver */
    if (window.ResizeObserver) {
      this.observer = new ResizeObserver((entries) => {
        // We only want to watch the mainViewer container,
        // grab the first entry (entries is the list of elements being observed)
        const mainViewer = entries[0]

        // Update state with new width and height
        this.setState({
          mainViewerWidth: mainViewer.contentRect.width,
          mainViewerHeight: mainViewer.contentRect.height,
        })
      })

      if (this.mainViewerRef.current) {
        this.observer.observe(this.mainViewerRef.current)
      }
    } else {
      console.error('ResizeObserver not supported')
    }
  }

  componentWillUnmount() {
    this.observer?.disconnect && this.observer.disconnect()
  }

  shouldComponentUpdate(newProps) {
    // reset state if asset changes
    if (this.props.asset != newProps.asset) {
      setTimeout(() => {
        // this will trigger another eval of shouldComponentUpdate,
        // which will return false.
        this.setState({
          currentIndex: 0,
          currentSelectedHotspot: null,
        })
      }, 0)
      return false
    }
    return true
  }

  setSelectedHotspot(index) {
    this.setState({ currentSelectedHotspot: index })
  }

  render() {
    const { currentIndex, currentSelectedHotspot } = this.state
    const { asset, allAssets, allFolders, endpoints } = this.props
    const { slides } = asset.product

    const { mainViewerWidth, mainViewerHeight } = this.state

    const currentSlide = slides[currentIndex]
    if (!currentSlide) return null
    const {
      size: { width: slideImageWidth, height: slideImageHeight },
      hotspots,
    } = currentSlide
    const currentSlideImageUrl = allAssets[currentSlide?.mainImage]?.url || null

    const isImageReady = !!(mainViewerWidth && mainViewerHeight)
    const listData = hotspots.map((hotspot, index) => ({
      ...allAssets[hotspot.assetId],
      index,
      title:
        hotspot.displayText ||
        allAssets[hotspot.assetId].title ||
        allAssets[hotspot.assetId].filename,
    }))

    return (
      <div style={styles.container}>
        <div style={styles.mainContentContainer}>
          {/* Make sure slide image exists in asset library before showing */}
          <div style={styles.mainViewer} ref={this.mainViewerRef}>
            <SlideImage
              {...{
                currentSlideImageUrl,
                isImageReady,
                mainViewerWidth,
                mainViewerHeight,
                slideImageHeight,
                slideImageWidth,
                hotspots,
                currentSelectedHotspot,
                setSelectedHotspot: this.setSelectedHotspot.bind(this),
              }}
            />{' '}
            <div
              style={{
                ...styles.prevNext,
                left: HOTSPOT__SIZE,
                bottom: HOTSPOT__SIZE,
                width: HOTSPOT__SIZE,
                height: HOTSPOT__SIZE,
              }}
              onClick={() =>
                this.setState({
                  currentIndex:
                    (slides.length + currentIndex - 1) % slides.length,
                  currentSelectedHotspot: null,
                })
              }
            >
              {' '}
              <span>&#10094;</span>{' '}
            </div>
            <div
              style={{
                ...styles.prevNext,
                right: HOTSPOT__SIZE,
                bottom: HOTSPOT__SIZE,
                width: HOTSPOT__SIZE,
                height: HOTSPOT__SIZE,
              }}
              onClick={() =>
                this.setState({
                  currentIndex: (currentIndex + 1) % slides.length,
                  currentSelectedHotspot: null,
                })
              }
            >
              {' '}
              <span>&#10095;</span>{' '}
            </div>
          </div>
          <div style={styles.paginationContainer}>
            {slides.map((val, idx) => (
              <div
                key={JSON.stringify(val)}
                style={{
                  ...styles.productPageIndicator,
                  backgroundColor: idx === currentIndex ? 'red' : 'transparent',
                }}
              />
            ))}
          </div>
        </div>
        <AssetList
          {...{
            listData,
            allFolders,
            endpoints,
            currentSelectedHotspot,
            setSelectedHotspot: this.setSelectedHotspot.bind(this),
          }}
        />
      </div>
    )
  }
}
