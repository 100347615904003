export default {
  'promach.bam.zone': {
    registrationLinkText: 'Request one here',
    registrationLink: 'https://info.promachbuilt.com/dpg-prm-account',
    privacyPolicy: 'https://www.promachbuilt.com/privacy/',
    termsAndConditions: 'https://www.promachbuilt.com/terms-and-conditions/',
    favicon: '/promach.ico',
    tabTitle: 'BAM! ProMach',
  },
  'titan.bam.zone': {
    favicon: '/titan.ico',
    tabTitle: 'BAM! Titan',
  },
  'autocrane.bam.zone': {
    favicon: '/autocrane.ico',
    tabTitle: 'BAM! Auto Crane',
  },
  'hcea.bam.zone': {
    favicon: '/hcea.png',
    tabTitle: 'BAM! HCEA',
  },
  'develon.bam.zone': {
    favicon: '/develon-icon.png',
    tabTitle: 'BAM! Develon',
  },
}
