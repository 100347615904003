import { Auth } from 'aws-amplify'
import md5 from 'md5'
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'
import BAMVersionMismatchError from '../modules/BAMVersionMismatchError'
import SessionTimeoutError from '../modules/SessionTimeoutError'
import * as appActions from './appActions'
import * as userActions from './user'

export const SECURE_FETCH = 'SECURE_FETCH'
export const BAM_FETCH = 'BAM_FETCH'
export const API_ERROR = 'API_ERROR'

/*
 * watchSecureFetch - watcher function to initiate saga.
 * NOTE - this saga does not have a public action creator - it is only initiated from
 *  within other sagas.
 */
export function* watchSecureFetch() {
  yield takeEvery(SECURE_FETCH, secureFetchSaga)
}

/*
 * secureFetchSaga - Saga to call fetch against the AWS API.
 *   First gets the token from redux user slice and validates/refreshes it
 *   If it cannot be refreshed, user is logged out.
 *   Next it calls fetch and returns the result
 * @param {
 *  type: SECURE_FETCH,
 *  url: `https://<somevalidurl>`,
 *  init: {
 *    method: '<POST>',
 *    body: {}
 *  }
 * }
 */
export function* secureFetchSaga(action) {
  try {
    const config = yield select((state) => state.config.appConfig)
    const clientVersion = yield select((state) => state.appState.version)
    const serverVersion = yield call(getBAMVersion, config)
    if (clientVersion && clientVersion !== serverVersion) {
      yield put({ type: appActions.VERSION_MISMATCH })
    }
    let result
    try {
      result = yield call([Auth, Auth.currentSession])
    } catch (error) {
      throw new SessionTimeoutError()
    }

    action.init.headers = {
      ...action.headers,
      Authorization: result.idToken.jwtToken,
    }

    return yield call(bamFetch, action.url, action.init)
  } catch (error) {
    console.log(
      'securefetch caught error',
      error,
      error.status || error.statusCode || 'noErrorStatusCode',
      error.result || 'noErrorResult'
    )

    if (error instanceof BAMVersionMismatchError) {
      yield put({ type: appActions.SET_VERSION_MISMATCH, mismatch: true })
      throw error
    } else if (
      error instanceof SessionTimeoutError ||
      error.forceLogout ||
      error.status === 401
    ) {
      // If its an authorization error, ask for user password.
      const originalAction = action
      const result = yield call(userActions.sessionTimeoutSaga, {})

      // If the user was able to log back in we can retry the original action
      // and return to function
      if (result) {
        return yield call(secureFetchSaga, originalAction)
      }
      // Otherwise throw a SessionTimeoutError
      const err = new SessionTimeoutError('Session timeout')
      throw err
      // All other BAM errors - log message to errors table
    } else if (error.bamError) {
      yield put({ type: API_ERROR, result: error.result })
      throw error
      // Other Fetch errors (network issues) - return to sender
    } else {
      throw error
    }
  }
}

/*
 * watchFetch - watcher function to initiate saga.
 * NOTE - this saga does not have a public action creator - it is only initiated from
 *  within other sagas (it's exported for unit testing purposes)
 */
export function* watchFetch() {
  yield takeEvery(BAM_FETCH, fetchSaga)
}

/*
 * fetchSaga - Saga to call fetch against the AWS API with no.
 *   This saga calls fetch without a token...only used for unsecured or signed urls
 *   The s3Put param is used to make sure fetch returns properly - an S3 PUT return null
 *   which would break res.json()
 * @param {
 *  type: BAM_FETCH,
 *  url: `https://<somevalidurl>`,
 *  init: {
 *    method: '<POST>',
 *    body: {}
 *    s3Put: bool
 *  }
 * }
 */
export function* fetchSaga(action) {
  try {
    const result = yield call(bamFetch, action.url, action.init, action.s3Put)
    return result
  } catch (error) {
    // If its an authorization error, force user logout.
    if (error.forceLogout || error.status === 401) {
      yield put({ type: userActions.LOGOUT_SUCCESS })
      yield put({ type: appActions.COMPLETE_ASYNC })
      throw error
      // All other BAM errors - log message to errors table
    } else if (error.bamError) {
      yield put({ type: API_ERROR, result: error.result })
      throw error
      // Other Fetch errors (network issues) - return to sender
    } else {
      throw error
    }
  }
}

/*
 * bamFetch - fetch wrapper to allow it to be called from wtihin saga
 */
const bamFetch = (url, init, s3Put) => {
  return fetch(url, init)
    .then((response) => parseJson(response, s3Put))
    .then((res) => {
      // This handles Lambda 400 responses
      if (!res.ok) {
        let err = new Error('BAM_ERROR')
        err.bamError = true
        err.status = res.status
        err.result = res.data
        err.result.url = url
        throw err
      }
      return res.data
    })
    .catch((error) => {
      //These errors are AWS unauthorized (401) or Lambda internal errors (502)
      if (error.status === 401) {
        let err = new Error('BAM_ERROR')
        err.forceLogout = true
        throw err
      } else if (error.status === 400) {
        // We just threw this!  Keep throwing
        throw error
      } else {
        let err = new Error('AWS_ERROR')
        err.bamError = true
        err.result = { clientMessage: 'Unhandled Fetch error', error, url }
        throw err
      }
    })
}

const parseJson = (response, s3Put) => {
  // Loading an object to S3 with a presigned URL returns an
  // Empty body...we don't want result.json() to throw an error.
  if (s3Put)
    return {
      ok: true,
      data: {},
    }

  return response.json().then((json) => {
    return {
      data: json,
      status: response.status,
      ok: response.ok,
    }
  })
}

export function* watchApiErrors() {
  yield takeEvery(API_ERROR, APIErrorSaga)
}

export function* APIErrorSaga(action) {
  const config = yield select((state) => state.config.appConfig)
  const timestamp = yield call(Date.now)
  const id = md5(`Error${timestamp}`)
  action.result.id = id
  action.result.timestamp = new Date().toString()
  yield call(secureFetchSaga, {
    url: `${config.baseUrl}/api/errors/log`,
    init: {
      method: 'POST',
      body: JSON.stringify(action.result),
    },
  })
  console.log(action.result)
  yield null
}

export async function getBAMVersion() {
  const response = await fetch(`/version.json`, {
    method: 'GET',
  })
  const version = await response.json()

  return version.BAMversion
}

export function* watchVersionMismatch() {
  yield takeLeading(appActions.VERSION_MISMATCH, versionMismatchSaga)
}

export function* versionMismatchSaga() {
  const path = window.location.href
  yield put({ type: appActions.SET_VERSION_MISMATCH, mismatch: true })
  yield put({ type: appActions.SET_COUNTDOWN_TIMER, value: 30 })
  yield put({
    type: appActions.SET_MAINTENANCE_MODE,
    maintenance: {
      state: 1,
      title: 'A new version of BAM! is available',
      message:
        'We’re always working hard to improve BAM! We’ve just pushed an update and your browser ' +
        'should automatically refresh so that you have the newest version.',
      prevPath: path,
      showCountdown: true,
    },
  })
  while (true) {
    yield delay(1000)
    yield put({ type: appActions.DECREMENT_COUNTDOWN_TIMER, value: 1 })
    const timer = yield select((state) => state.appState.timer)
    if (timer === 0) {
      window.location.replace(path)
    }
  }
}

export default all([
  watchSecureFetch(),
  watchFetch(),
  watchApiErrors(),
  watchVersionMismatch(),
])
