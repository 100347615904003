import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import * as permissionsActions from '../../../dux/actions/permissions'
import * as invitationActions from '../../../dux/actions/invitation'
import * as userActions from '../../../dux/actions/user'
import { connect } from 'react-redux'
import * as d3 from 'd3-dsv'

const styles = {
  form: { display: 'flex', flexDirection: 'column', color: 'black' },
  label: { fontWeight: '1000px', fontSize: '30px' },
  button: { color: 'black' },
  disabled: { color: 'red' },
}

// | first | last | email | password | invite | groups (names, semicolon-delimited) |
// need one of: Invite or Groups, all others are required
class UploadUsers extends Component {
  constructor(props) {
    super(props)
    this.handleFileChange = ({ target: { name, files } }) => {
      this.setState((prev) => ({ ...prev, [name]: files[0] }))
    }
  }

  csvToJson(file) {
    const reader = new FileReader()
    reader.onload = ({ target: { result } }) => {
      const json = d3.csvParse(result)
      this.props.userActions.importUsers(json)
    }
    reader.readAsText(file)
  }

  submitHandler = (e) => {
    e.preventDefault()
    this.csvToJson(this.state.users)
  }

  render() {
    return (
      <>
        <form style={styles.form} onSubmit={this.submitHandler}>
          <label htmlFor="users" style={styles.label}>
            Users
          </label>
          <input
            type="file"
            onChange={this.handleFileChange}
            name="users"
            id="users"
            accept=".csv"
          />
          <button type="submit" style={styles.button}>
            Upload
          </button>
        </form>
        <div>UploadUsers</div>
      </>
    )
  }
}

// What will happen when we call these sagas in a loop like this?

const mapDispatchToProps = (dispatch) => ({
  permissionsActions: bindActionCreators(permissionsActions, dispatch),
  invitationActions: bindActionCreators(invitationActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch),
})
const mapStateToProps = (state) => ({
  user: state.user,
})
export default connect(mapStateToProps, mapDispatchToProps)(UploadUsers)
