import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActions from '../../dux/actions/appActions'
import * as modalActions from '../../dux/actions/modal'
import * as actions from '../../dux/actions/settings'
import '../styles/Settings.css'

class MSTBrandingSettings extends React.Component {
  state = {
    logo: this.props.settings.branding.logo,
    logoMsg: this.props.settings.branding.logoMsg,
    splashScreen: this.props.settings.branding.splashScreen,
    brandProfile: this.props.settings.branding.brandProfile,
    brandProfileMsg: this.props.settings.branding.brandProfileMsg,
    splashScreenMsg: this.props.settings.branding.splashScreenMsg,
    appName: this.props.settings.branding.appName,
    registrationMsg: this.props.settings.branding.registrationMsg,
  }

  componentDidMount() {
    this.props.actions.getSetting('branding')
    this.props.appActions.scrollToTop('main-content')
  }

  componentDidUpdate(prevProps) {
    if (this.props.settings.branding.logo && !prevProps.settings.branding.logo)
      this.setState(() => ({ logo: this.props.settings.branding.logo }))

    if (
      this.props.settings.branding.splashScreen &&
      !prevProps.settings.branding.splashScreen
    )
      this.setState(() => ({
        splashScreen: this.props.settings.branding.splashScreen,
      }))

    if (
      this.props.settings.branding.brandProfile &&
      !prevProps.settings.branding.brandProfile
    )
      this.setState(() => ({
        brandProfile: this.props.settings.branding.brandProfile,
      }))

    if (
      this.props.settings.branding.logoMsg &&
      !prevProps.settings.branding.logoMsg
    )
      this.setState(() => ({ logoMsg: this.props.settings.branding.logoMsg }))

    if (
      this.props.settings.branding.splashScreenMsg &&
      !prevProps.settings.branding.splashScreenMsg
    )
      this.setState(() => ({
        splashScreenMsg: this.props.settings.branding.splashScreenMsg,
      }))

    if (
      this.props.settings.branding.brandProfileMsg &&
      !prevProps.settings.branding.brandProfileMsg
    )
      this.setState(() => ({
        brandProfileMsg: this.props.settings.branding.brandProfileMsg,
      }))

    if (
      this.props.settings.branding.appName &&
      !prevProps.settings.branding.appName
    )
      this.setState(() => ({
        appName: this.props.settings.branding.appName,
      }))

    if (
      this.props.settings.branding.registrationMsg &&
      !prevProps.settings.branding.registrationMsg
    )
      this.setState(() => ({
        registrationMsg: this.props.settings.branding.registrationMsg,
      }))
  }

  uploadCallback = (succeeded, failed, files, type) => {
    if (succeeded.length > 0) {
      this.validateFile(
        files.filter((file) => file.id === succeeded[0])[0].url,
        type
      )
      this.props.actions.setBrandingMessage(null, null)
      this.props.modalActions.closeModal()
    }
  }

  /* Calls saga to check file size */
  /* Provides a callback to set state with the validation message */
  validateFile = (file, type) => {
    if (file)
      this.props.actions.validateFile(file, type, (err, msg) => {
        this.setState(() => ({ [`${type}Msg`]: err || msg }))
        if (!err) this.setState(() => ({ [type]: file }))
      })

    return null
  }

  onSave = () => {
    const { logo, splashScreen, brandProfile } = this.state

    if (
      logo !== this.props.settings.branding.logo ||
      splashScreen !== this.props.settings.branding.splashScreen ||
      brandProfile !== this.props.settings.branding.brandProfile
    ) {
      this.props.actions.saveSettings('branding', { ...this.state })
      return this.props.actions.setBrandingMessage('Changes saved', 'SUCCESS')
    }

    return this.props.actions.setBrandingMessage('No changes to save', 'ERRROR')
  }

  onCancel = () => {
    this.setState({
      logo: this.props.settings.branding.logo,
      logoMsg: this.props.settings.branding.logoMsg,
      splashScreen: this.props.settings.branding.splashScreen,
      brandProfile: this.props.settings.branding.brandProfile,
      brandProfileMsg: this.props.settings.branding.brandProfileMsg,
      splashScreenMsg: this.props.settings.branding.splashScreenMsg,
    })
    this.props.actions.setBrandingMessage('Changes cancelled', 'ERRROR')
  }

  render() {
    return (
      <div className="settings__container">
        <div className="settings__form-container">
          <div className="builders__form-label">Logo Upload</div>
          <div className="builders__padded-div">
            Upload a cover photo with your logo here. Ideal dimensions are 700 x
            360px.
          </div>
          <div className="builders__padded-div">
            Your logo will appear on the User Profile page in the Mobile Sales
            tool and the BAM! viewer asset sharing website.
          </div>
          <div>
            <img
              className="settings__logo-image"
              src={`${this.state.logo}_thumb-350x180`}
              alt="logo"
            />
          </div>
          <div className="builders__padded-div settings__action-remove">
            {this.state.logoMsg}
          </div>
          <Button
            className="settings__button"
            onClick={() =>
              this.props.modalActions.openModal('UploadModal', {
                type: 'support',
                multiple: false,
                target: 'active',
                imageOnly: true,
                callback: (succeeded, failed, files) =>
                  this.uploadCallback(succeeded, failed, files, 'logo'),
              })
            }
          >
            <span>
              <i className="fa fa-plus-circle" /> Image
            </span>
          </Button>
          <div className="builders__form-label">Brand Profile Upload</div>
          <div className="builders__padded-div">
            Upload your brand profile image here. Ideal dimensions are 360 x
            360px.
          </div>
          <div className="builders__padded-div">
            Your brand profile image (usually a logo) will appear in messages
            sent from the Mobile Sales Tool like shared assets, calculations,
            and custom emails.
          </div>
          <div>
            <img
              className="settings__profile-image"
              src={`${this.state.brandProfile}_thumb-180x180`}
              alt="brand profile"
            />
          </div>
          <div className="builders__padded-div settings__action-remove">
            {this.state.brandProfileMsg}
          </div>
          <Button
            className="settings__button"
            onClick={() =>
              this.props.modalActions.openModal('UploadModal', {
                type: 'support',
                multiple: false,
                target: 'active',
                imageOnly: true,
                callback: (succeeded, failed, files) =>
                  this.uploadCallback(succeeded, failed, files, 'brandProfile'),
              })
            }
          >
            <span>
              <i className="fa fa-plus-circle" /> Image
            </span>
          </Button>
          <div className="builders__form-label builders__padded-div-large">
            Splash Screen Upload
          </div>
          <div className="builders__padded-div">
            Upload your splash screen with your logo here. Users will see this
            upon opening the app while content is loading. Ideal dimensions are
            750 x 1334px.
          </div>
          <div>
            <img
              className="settings__splash-image"
              src={`${this.state.splashScreen}_thumb-375x667`}
              alt="splash screen"
            />
          </div>
          <div className="builders__padded-div settings__action-remove">
            {this.state.splashScreenMsg}
          </div>
          <Button
            className="settings__button"
            onClick={() =>
              this.props.modalActions.openModal('UploadModal', {
                type: 'support',
                multiple: false,
                target: 'active',
                imageOnly: true,
                callback: (succeeded, failed, files) =>
                  this.uploadCallback(succeeded, failed, files, 'splashScreen'),
              })
            }
          >
            <span>
              <i className="fa fa-plus-circle" /> Image
            </span>
          </Button>
          <div className="builders__button-container">
            <span className="builders__button">
              <Button onClick={this.onCancel}>Cancel</Button>
            </span>
            <span className="builders__button">
              <Button
                type="primary"
                onClick={this.onSave}
                loading={this.props.asyncPending}
              >
                Save
              </Button>
            </span>
          </div>
          <div className="builders__fixed-div">
            {this.props.settings.brandingMessage && (
              <div>
                {this.props.settings.brandingMessageType === 'SUCCESS' ? (
                  <i className="fa fa-check-circle assettable__status-green" />
                ) : (
                  <i className="fa fa-exclamation-circle assettable__status-red" />
                )}
                &nbsp; {this.props.settings.brandingMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

MSTBrandingSettings.propTypes = {
  asyncPending: PropTypes.bool,
  settings: PropTypes.object,
  modalActions: PropTypes.object,
  appActions: PropTypes.object,
  actions: PropTypes.object,
}

const mapStateToProps = (state) => ({
  asyncPending: state.appState.asyncPending,
  settings: state.settings,
})

const mapDispatchToProps = (dispatch) => ({
  modalActions: bindActionCreators(modalActions, dispatch),
  appActions: bindActionCreators(appActions, dispatch),
  actions: bindActionCreators(actions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MSTBrandingSettings)
